<template>
  <div class="page">
    <el-form :model="formInfo" label-width="140px" :rules="rules" ref="formInfo">
      <el-card class="no-hidden">
        <template slot="header">
          <span class="title">基础信息</span>
          <div class="button-back">
            <el-button @click="() => {
      $router.go(-1);
    }
      " size="small" class="back_btn">
              <img src="@/assets/back.png" alt="" />
            </el-button>
          </div>
        </template>
        <div class="card-body">
          <el-row type="flex" justify="space-between">
            <el-col :span="6">
              <el-form-item label="来源应用" prop="shop_type">
                <el-select style="width: 220px" size="medium" @change="changeAppType" :disabled="isEdit"
                  v-model="formInfo.shop_type" placeholder="请选择来源应用" clearable>
                  <el-option v-for="item in mallTypeList" :key="item.value" :label="item.name"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between">
            <el-col :span="8">
              <el-form-item label="客户名称" prop="customer_name">
                <el-select @change="customerChange" size="small" filterable placeholder="请选择客户名称"
                  v-model="formInfo.customer_name" clearable style="width: 200px">
                  <el-option v-for="item in customerList" :key="item.id" :value="item.name" :label="item.name" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="代理名称">
                <el-input :disabled="true" placeholder="代理名称" v-model.trim="formInfo.agent_person"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系人名称">
                <el-input :disabled="true" placeholder="联系人名称" v-model.trim="formInfo.contacts_person"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between">
            <el-col :span="8">
              <el-form-item label="联系电话">
                <el-input :disabled="true" placeholder="联系电话" v-model.trim="formInfo.contacts_phone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="销售人员">
                <en-sale-search placeholder="请输入销售人员" v-model="formInfo.seller_name" :disabled="true"
                  @change="handleSelectSell" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="制单人名称">
                <el-autocomplete style="width: 100%" :trigger-on-focus="false" placeholder="请输入制单人名称"
                  @focus="returnMatchSuggestion('WRIGHT')" :fetch-suggestions="makeOrderScreen" size="small"
                  v-model.trim="formInfo.operator_name" :disabled="true" clearable></el-autocomplete>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="客户地址">
            <el-input :disabled="true" placeholder="客户地址" v-model.trim="formInfo.customer_address"></el-input>
          </el-form-item>
        </div>
      </el-card>
      <el-card>
        <template slot="header">
          <span class="title">扣款用户信息</span>
        </template>
        <div class="card-body-1">
          <el-form-item label="所属商城" prop="shop_id">
            <el-tooltip :content="formInfo.shop_name" :disabled="(formInfo.shop_name || '').length < 12" placement="top"
              effect="light"><el-select size="small" v-model="formInfo.shop_id" filterable clearable
                style="width: 200px" @change="handleShopChange" @focus="handleShopFocus">
                <el-option v-for="(item, index) in shopList" :key="index" :value="item.shop_id"
                  :label="item.shop_name" />
              </el-select>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="是否显示扣款明细">
            <el-radio-group v-model="formInfo.mobile_display_refund_detail">
              <el-radio :label="1">显示</el-radio>
              <el-radio :label="2">不显示</el-radio>
            </el-radio-group>
            <br />
            <span>说明：【是否显示扣款明细】功能用于控制是否在C端用户的账户余额处显示“因销售退款单”造成的金额扣减明细。</span>
          </el-form-item>
          <el-form-item label="账号类型" v-if="formInfo.shop_type == 2">
            <el-radio-group v-model="formInfo.account_type" :disabled="disabled">
              <el-radio :label="1">手机号</el-radio>
              <el-radio :label="2">账号</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否短信通知用户" v-if="formInfo.account_type == 1">
            <el-radio-group v-model="formInfo.message_note">
              <el-radio :label="2">不通知</el-radio>
              <el-radio :label="1">通知</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="扣款账号">
            <p>
              <upload-excel :showImportCount="false" type="primary" :disabledButton="formInfo.shop_name == ''"
                text="批量导入" show-import-count :on-success="excelSuccess" :beforeUpload="beforeUpload"
                slot="reference" />
              <el-button size="small" type="text" @click="downTemplate">下载导入模板</el-button>
              <el-tooltip class="item" effect="dark" placement="top-start">
                <div slot="content">
                  批量导入注意事项:<br />
                  1、导入的文件仅支持xls、xlsx、csv格式的Excel文件。<br />
                  2、填写表格数据时，姓名字段可不填，其他项为必填项。<br />
                  3、请保证上传的Excel文件中所有手机号/账号均在第一列，且每个单元格中只有1个手机号/账号。<br />
                  4、每次最多允许导入1000条数据。
                </div>
                <i class="el-icon-warning"></i>
              </el-tooltip>
            </p>
            <el-table style="width:750px" height="350" v-if="formInfo.account_type == 1" :data="phoneTableData" border>
              <el-table-column label="姓名">
                <template slot-scope="scope">
                  <el-input size="small" v-model.trim="scope.row.user_name" maxlength="20" placeholder="姓名"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="手机号" :render-header="addRedStar">
                <template slot-scope="scope">
                  <div style="padding:6px 0">
                    <el-input @blur="mobileBlur(scope.row.phone_number, scope.$index)" size="small"
                      v-model.trim="scope.row.phone_number" placeholder="手机号" maxlength="11"></el-input>
                    <span class="errorMessage" v-show="scope.row.mobileError !== ''">{{ scope.row.mobileError }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="扣款金额" :render-header="addRedStar">
                <template slot-scope="scope">
                  <el-input @blur="moneyBlur(scope.row.money, scope.$index)" size="small" v-model.trim="scope.row.money"
                    placeholder="扣款金额"></el-input>
                  <span class="errorMessage" v-show="scope.row.moneyError !== ''">{{ scope.row.moneyError }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" fixed="right" width="120">
                <template slot-scope="scope">
                  <el-button style="font-size:25px;padding:0" size="small"
                    @click="addTabData('phone_number', scope.$index)" type="text"
                    icon="el-icon-circle-plus-outline"></el-button>
                  <el-button style="font-size:25px;padding:0" size="small"
                    @click="delTabData('phone_number', scope.$index)" type="text" :disabled="phoneTableData.length < 2"
                    icon="el-icon-remove-outline"></el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-table style="width:750px" height="350" v-else :data="userTableData" border>
              <el-table-column label="姓名">
                <template slot-scope="scope">
                  <el-input size="small" v-model.trim="scope.row.user_name" maxlength="20" placeholder="姓名"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="账号" :render-header="addRedStar">
                <template slot-scope="scope">
                  <el-input size="small" @blur="accountBlur(scope.row.account, scope.$index)" v-model.trim="scope.row.account" placeholder="账号" maxlength="16"></el-input>
                  <span class="errorMessage" v-show="scope.row.accountError !== ''">{{ scope.row.accountError }}</span>
                </template>
              </el-table-column>
              <el-table-column label="扣款金额" :render-header="addRedStar">
                <template slot-scope="scope">
                  <el-input @blur="moneyBlur(scope.row.money, scope.$index)" size="small" v-model.trim="scope.row.money"
                    placeholder="扣款金额"></el-input>
                  <span class="errorMessage" v-show="scope.row.moneyError !== ''">{{ scope.row.moneyError }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" fixed="right" width="120">
                <template slot-scope="scope">
                  <el-button style="font-size:25px;padding:0" size="small" @click="addTabData('user', scope.$index)"
                    type="text" icon="el-icon-circle-plus-outline"></el-button>
                  <el-button style="font-size:25px;padding:0" size="small" @click="delTabData('user', scope.$index)"
                    type="text" :disabled="userTableData.length < 2" icon="el-icon-remove-outline"></el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-row style="width: 55%">
            <el-col :span="9">
              <el-form-item label="折扣率" prop="discount_rate">
                <el-input style="width:140px;text-align:center;" @blur="rateblur"
                  oninput="if(!/^(?!0{2,})(\d{1,2}|\d{1,2}\.\d{1,2})$/.test(value)) value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');if(value>100)value=100;if(value<0)value=null;if((value[0] == 0 && value[1] > 0) || value == '00')value=value.slice(1);"
                  v-model.trim="formInfo.discount_rate"><template slot="append">%</template></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4" :offset="6">
              <el-form-item label="扣款人数">
                <el-input size="small" :controls="false" :precision="2" style="width: 200px"
                  v-model="formInfo.recharge_number" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="width: 55%">
            <el-col :span="4">
              <el-form-item label="合计总金额">
                <el-input-number size="small" :controls="false" :precision="2" style="width: 200px"
                  v-model="formInfo.total_money" disabled></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="4" :offset="11">
              <el-form-item label="优惠后总金额">
                <el-input-number size="small" :controls="false" :precision="2" style="width: 200px"
                  v-model="formInfo.discounts_total_money" disabled></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="备注信息">
            <el-input size="small" type="textarea" placeholder="请输入200字以内的备注信息" rows="5" resize="none"
              v-model.trim="formInfo.remark" :maxlength="200" />
          </el-form-item>
          <el-form-item align="center" style="width: 80%">
            <el-button type="primary" :disabled="saveDisabled" @click="isPermission">保存</el-button>
          </el-form-item>
        </div>
      </el-card>
    </el-form>
    <el-dialog-x-dialog title="导入失败" :visible.sync="inportErrVisible">
      <div style="display:flex;align-items:center;padding:20px;">
        <div style="font-size:80px;color:red" class="el-icon-error"></div>
        <p>很抱歉，因导入信息有误，用户数据导入失败，请点击下载<el-button @click="downloadErrFile" type="text"
            style="text-decoration: underline;">失败原因文件</el-button>，修改或删除后重新导入。</p>
      </div>
    </el-dialog-x-dialog>
  </div>
</template>

<script>
import { Foundation } from "@/../ui-utils";
import * as API_saleCard from "@/api/saleCard";
import mixins from "./saleOrderRefundMixins";
import * as API_Order from "@/api/order";
import { getShopLv } from '@/api/shop';
import { asyncRouterMap } from "@/router";
import cardList from "@/views/tools/sale-card-open/components/cardList";
import cardUpload from "@/views/tools/sale-card-open/components/cardUpload";
import { handleDownload, downloadErr } from '@/utils';
import UploadExcel from '@/components/UploadExcel';
import { RegExp } from '@/../ui-utils';
import { getAllCustomerListByEnterpriseId, checkoutMember } from "@/api/settingCustomer";
export default {
  name: "addSaleOrderRecord",
  mixins: [mixins],
  components: {
    cardList,
    cardUpload,
    UploadExcel,
  },
  data () {
    return {
      mallTypeList: [],
      inportErrVisible: false,//失败原因弹窗
      errorForm: {},//失败原因
      customerList: [],//客户档案列表
      phoneTableData: [//手机号类型
        {
          user_name: '',
          phone_number: '',
          money: '',
          mobileError: '',
          moneyError: ''
        }
      ],
      userTableData: [{//账号类型
        user_name: '',
        account: '',
        money: '',
        accountError: '',
        moneyError: ''
      }],
      isEdit: !!this.$route.query.id,
      id: this.$route.query.id,
      disabled: !!this.$route.query.disabled,
      formInfo: {
        trade_source: 1,//单据来源 1企业后台 2移动端前台
        trade_type: 2,//单据类型 1扣款 2扣款
        shop_type: '',//来源应用
        mobile_display_refund_detail: 1,//是否显示扣款明细 1是 2不是
        account_type: 1,//账号类型  1手机号 2账号
        message_note: 2,//是否短信通知用户 1通知  2不通知
        shop_id: "",//商城id
        shop_name: "",//商城名称
        customer_name: "",//客户
        customer_id: '',//客户id
        agent_person: "",//代理人
        contacts_person: "",//联系人
        contacts_phone: "",//联系电话
        customer_address: "",//地址
        seller_name: "",//销售人员
        operator_name: !this.$route.query.id ? (this.$store.getters.user.nickname || "") : "",
        cardList: [],
        discount_rate: 100,//折扣率
        recharge_number: 0,//扣款人数
        total_money: 0,//合计总金额
        discounts_total_money: 0,//优惠后总金额
        remark: "",//备注信息
      },
      rules: {
        shop_type: [
          {
            required: true,
            message: "请选择来源应用",
            trigger: ["blur", "change"],
          },
        ],
        customer_name: [
          {
            required: true,
            message: "请输入客户名称",
            trigger: ["blur", "change"],
          },
        ],
        shop_id: [
          { required: true, message: "请选择所属商城", trigger: "change" },
        ],
        discount_rate: [
          { required: true, message: "请填写折扣率", trigger: "blur" },
        ],
      },
      saveDisabled: false,
    };
  },
  watch: {
    'formInfo.shop_name': {
      handler (val) {
        if (val == '') {
          this.phoneTableData = [//手机号类型
            {
              user_name: '',
              phone_number: '',
              money: '',
              mobileError: '',
              moneyError: ''
            }
          ]
          this.userTableData = [{//账号类型
            user_name: '',
            account: '',
            money: '',
            accountError: '',
            moneyError: ''
          }]
        }
      },
      deep: true
    },
    'formInfo.discount_rate': {
      handler (val) {
        this.formInfo.discounts_total_money = Number(this.formInfo.total_money) * Number(this.formInfo.discount_rate) / 100
      },
      deep: true
    },
    'phoneTableData': {
      handler (val) {
        if (this.formInfo.account_type == 1) {//手机号
          this.formInfo.recharge_number = val.length //扣款人数
          this.formInfo.total_money = val.reduce((total, item) => {//合计总金额
            return total + Number(item.money)
          }, 0)
          this.formInfo.discounts_total_money = Number(this.formInfo.total_money) * Number(this.formInfo.discount_rate) / 100
        }
      },
      deep: true
    },
    'userTableData': {
      handler (val) {
        if (this.formInfo.account_type == 2) {//账号
          console.log(121212);
          this.formInfo.recharge_number = val.length //扣款人数
          this.formInfo.total_money = val.reduce((total, item) => {//合计总金额
            return total + Number(item.money)
          }, 0)
          this.formInfo.discounts_total_money = Number(this.formInfo.total_money) * Number(this.formInfo.discount_rate) / 100
        }
      },
      deep: true
    }
  },
  async mounted () {
    //判断大平台开关
    const doubledata = await API_Order.getShopExtAuth();
    const aicard20 = await getShopLv();
    if (aicard20.data.has_cash_mall === 1) {
      this.mallTypeList.push({ name: "现金商城", value: 1 })
    }
    if (doubledata.double_choice_mall_open == "OPEN") this.mallTypeList.push({ name: '双选商城', value: 2 })
    const resdata = await getAllCustomerListByEnterpriseId(this.$store.getters.shopInfo.shop_id)
    this.customerList = resdata.filter(item => item.status == 1)
    this.getDataInfo();
    this.checkPermission();
    this.getClientList();
  },
  methods: {
    rateblur () {
      let value = this.formInfo.discount_rate;
      const money = /^\d+(\.\d{1,2})?$/
      if (!RegExp.money.test(value) || !money.test(value)) {
        this.formInfo.discount_rate = ''
      }
    },
    customerChange (val) {//客户选择事件
      if (val) {
        const selectData = this.customerList.find(res => res.name == this.formInfo.customer_name)
        this.formInfo.customer_id = selectData.id;//客户id
        this.formInfo.agent_person = selectData.agent_person;//代理名称
        this.formInfo.contacts_person = selectData.contacts_person;//联系人名称
        this.formInfo.contacts_phone = selectData.contacts_phone;//联系电话
        this.formInfo.seller_name = selectData.sell_name;//销售人员名称
        this.formInfo.seller_id = selectData.salesman_id;//销售人员id
        this.formInfo.customer_address = selectData.customer_address;//客户地址
      } else {
        this.formInfo.customer_id = '';//客户id
        this.formInfo.agent_person = '';//代理名称
        this.formInfo.contacts_person = '';//联系人名称
        this.formInfo.contacts_phone = '';//联系电话
        this.formInfo.seller_name = '';//销售人员名称
        this.formInfo.seller_id = '';//销售人员id
        this.formInfo.customer_address = '';//客户地址
      }
    },
    /**手机号失焦检测*/
    async mobileBlur (value, index) {
      const activeName = this.formInfo.account_type == 1 ? 'phoneTableData' : 'userTableData';
      if (this.formInfo.shop_name == '') {
        this[activeName][index].mobileError = "请选择所属商城";
      } else if (value == '') {
        this[activeName][index].mobileError = "请填写正确的手机号";
      } else if (!RegExp.mobile.test(value)) {
        this[activeName][index].mobileError = "请填写正确的手机号";
      } else if (this[activeName].length > 1 && this.repeatChange(this[activeName], value)) {
        this[activeName][index].mobileError = "当前手机号已存在";
      }
      else if ((await checkoutMember({ phone_number: value, shop_id: this.formInfo.shop_id })) == false) {
        this[activeName][index].mobileError = "账号不存在于所选商城";
      }
      else {
        this[activeName][index].mobileError = "";
      }
    },
    /** 手机号或账号是否重复*/
    repeatChange (list, value) {
      let arr = []
      if (this.formInfo.account_type == 1) {
        list.forEach(item => {
          if (item.phone_number == value) {
            arr.push(item)
          }
        })
      } else {
        list.forEach(item => {
          if (item.account == value) {
            arr.push(item)
          }
        })
      }
      return arr.length > 1
    },
    /**账号失焦检测 */
    accountBlur (value, index) {
      const activeName = this.formInfo.account_type == 1 ? 'phoneTableData' : 'userTableData';
      const regex = /^[\u4e00-\u9fffA-Za-z0-9]{2,16}$/;
      if (value == '') {
        this[activeName][index].accountError = "请填写正确的帐号";
      } else if (!regex.test(value)) {
        this[activeName][index].accountError = "请填写正确的帐号";
      } else if (this[activeName].length > 1 && this.repeatChange(this[activeName], value)) {
        this[activeName][index].accountError = "当前账号已存在";
      } else {
        this[activeName][index].accountError = "";
      }
    },
    /**金额失焦检测*/
    moneyBlur (value, index) {
      const activeName = this.formInfo.account_type == 1 ? 'phoneTableData' : 'userTableData';
      const money = /^\d+(\.\d{1,2})?$/
      if (value == '') {
        this[activeName][index].moneyError = "请填写扣款金额";
      } else if (value > 10000) {
        this[activeName][index].moneyError = "最大值为10000";
      } else if (!RegExp.money.test(value) || !money.test(value) || value == 0) {
        this[activeName][index].moneyError = "请填写最多到小数点后两位的正数";
      } else {
        this[activeName][index].moneyError = "";
      }
    },
    beforeUpload (file) {
      console.log(file.name.split('.'));
      const name = file.name.split('.')
      const file_type = name[name.length - 1].indexOf('xlsx') > -1 || name[name.length - 1].indexOf('xls') > -1
      console.log(file_type);
      if (!file_type) {
        this.$message.error('请上传xls，xlsx格式的文件')
      }
      return file_type
    },
    /**导入文件成功回调 */
    async excelSuccess ({ results }) {
      if (results.length == 0) {
        return this.$message.error("导入失败，因导入的文件中没有相关数据")
      }
      if (this.formInfo.account_type == 1) {
        const list = ['姓名', '手机号', '扣款金额']
        const keys = Object.keys(results[0]);
        if (keys.every(item => list.includes(item)) == false) {
          return this.$message.error("导入失败，因导入的文件格式不正确，请选择正确格式的文件")
        } else if (!results[0]["姓名"] && !results[0]["手机号"] && !results[0]["扣款金额"]) {
          return this.$message.error("导入失败，因导入的文件中没有相关数据")
        }
      } else {
        const list = ['姓名', '账号', '扣款金额']
        const keys = Object.keys(results[0]);
        if (keys.every(item => list.includes(item)) == false) {
          return this.$message.error("导入失败，因导入的文件格式不正确，请选择正确格式的文件")
        } else if (!results[0]["姓名"] && !results[0]["账号"] && !results[0]["扣款金额"]) {
          return this.$message.error("导入失败，因导入的文件中没有相关数据")
        }
      }
      let resMap = {
        姓名: 'user_name',
        账号: 'account',
        扣款金额: 'money_str',
      }
      if (this.formInfo.account_type == 1) {
        resMap = {
          姓名: 'user_name',
          手机号: 'phone_number',
          扣款金额: 'money_str',
        }
      }

      const newList = results.map(res => {
        let obj = {};
        Object.entries(res).forEach(([label, value]) => {
          obj[resMap[label]] = res[label]
        })
        return obj
      })
      if (newList.length > 1000) {
        this.$message.error('导入失败，因导入文件中的数据量超过1000条，请进行删减后重试');
      }
      if (this.formInfo.account_type == 2) {//账号
        const tableData = this.userTableData.filter(item => {
          if (item.account !== '' && item.money !== '' && item.accountError == '' && item.moneyError == '') {
            return item
          }
        })
        const form = {
          account_type: 2,
          type: 2,//退款
          shop_id: this.formInfo.shop_id,//商城id
          trade_recharge_member_pass_vos: tableData,
          trade_recharge_member_vos: newList,

        }

        const res = await API_saleCard.importMember(form)
        if (res.verify == 1) { //成功
          newList.forEach(item => {
            item.accountError = ''
            item.moneyError = ''
            item.money = item.money_str
          })
          if (this.userTableData.length == 1) {
            if (!this.userTableData[0]["user_name"] && !this.userTableData[0]["account"] && !this.userTableData[0]["money"]) this.userTableData = [...newList]
            else this.userTableData = this.userTableData.concat(newList)
          } else {
            this.userTableData = this.userTableData.concat(newList)
          }
        } else {//失败
          this.inportErrVisible = true;
          res.trade_recharge_member_vos.forEach(item => {
            item.reason = item.reason.join('')
          })
          this.errorForm = res;
        }

      } else {//手机号
        const tableData = this.phoneTableData.filter(item => {
          if (item.phone_number !== '' && item.money !== '' && item.mobileError == '' && item.moneyError == '') {
            return item
          }
        })
        const form = {
          account_type: 1,
          type: 2,//退款
          shop_id: this.formInfo.shop_id,//商城id
          trade_recharge_member_pass_vos: tableData,
          trade_recharge_member_vos: newList,
        }
        const res = await API_saleCard.importMember(form)
        if (res.verify == 1) {//成功
          newList.forEach(item => {
            item.mobileError = ''
            item.moneyError = ''
            item.money = item.money_str
          })
          if (this.phoneTableData.length == 1) {
            if (!this.phoneTableData[0]["user_name"] && !this.phoneTableData[0]["phone_number"] && !this.phoneTableData[0]["money"]) this.phoneTableData = [...newList]
            else this.phoneTableData = this.phoneTableData.concat(newList)
          } else {
            this.phoneTableData = this.phoneTableData.concat(newList)
          }
        } else {//失败
          this.inportErrVisible = true;
          res.trade_recharge_member_vos.forEach(item => {
            item.reason = item.reason.join('')
          })
          this.errorForm = res;
        }
      }

    },
    /**下载失败原因 */
    downloadErrFile () {
      let templateHeader = ['姓名', `${this.errorForm.account_type == 1 ? '手机号' : '账号'}`, '扣款金额', '失败原因'];
      let templateKey = ['user_name', `${this.errorForm.account_type == 1 ? 'phone_number' : 'account'}`, 'money_str', 'reason'];
      downloadErr(templateHeader, this.errorForm.trade_recharge_member_vos.map(item => templateKey.map(head => item[head])), '失败原因')

    },
    downTemplate () {//下载批量导入模板
      let value = [{ user_name: "", phone_number: "", money_str: "" }],
        templateKey = ['user_name', 'phone_number', 'money_str'],
        templateHeader = ['姓名', '手机号', '扣款金额'];
      if (this.formInfo.account_type == 2) {
        value = [{ user_name: "", account: "", money_str: "" }]
        templateKey = ['user_name', 'account', 'money_str']
        templateHeader = ['姓名', '账号', '扣款金额']
      }
      handleDownload(value, templateHeader, templateKey, '扣款导入模板');
    },
    addRedStar (h, { column }) {//表头添加*号
      let _output = [];
      if (['手机号', '账号', '扣款金额'].includes(column.label)) {
        _output = [
          h('span', { style: 'color: red' }, '*'),
          h('span', ' ' + column.label)
        ]
      } else {
        _output = [h('span', ' ' + column.label)]
      }
      return _output
    },
    /**添加表格数据 */
    addTabData (name, index) {
      if (name == 'phone_number') {
        this.phoneTableData.splice(index + 1, 0, { user_name: '', phone_number: '', money: '', mobileError: '', moneyError: '' })
      } else {
        this.userTableData.splice(index + 1, 0, { user_name: '', account: '', money: '', accountError: '', moneyError: '' })
      }
    },
    /**删除表格数据 */
    delTabData (name, index) {
      if (name == 'phone_number') {
        this.phoneTableData.splice(index, 1)
      } else {
        this.userTableData.splice(index, 1)
      }
    },
    getDataInfo () {
      if (!this.isEdit) return;
      API_saleCard.getMemberDetail(this.id).then((res) => {
        res.discount_rate = Foundation.rideitionNumber(res.discount_rate, 100);
        console.log(res);
        this.getShopList(res.shop_type);
        this.formInfo = res;
        if (this.formInfo.account_type == 1) {
          this.phoneTableData = res.trade_recharge_member_dos.map(item => {
            return {
              id: item.id,
              phone_number: item.phone_number,
              user_name: item.user_name,
              money: item.money,
              moneyError: '',
              mobileError: ''
            }
          })
          console.log(this.phoneTableData);
        } else {
          this.userTableData = res.trade_recharge_member_dos.map(item => {
            return {
              id: item.id,
              account: item.account,
              user_name: item.user_name,
              money: item.money,
              moneyError: '',
              accountError: ''
            }
          })
        }
      })
    },
    /**来源应用选择 */
    changeAppType (v) {
      this.$nextTick(() => {
        this.formInfo.cardList = [];
      });
      this.formInfo.shop_id = "";
      this.formInfo.shop_name = "";
      this.formInfo.account_type = 1;
      if (v) {
        this.getShopList(this.formInfo.shop_type);
      } else {
        this.shopList = [];
      }
    },

    // 商城下拉列表change事件
    handleShopChange (val) {
      this.formInfo.shop_name = this.getShopName(val)
    },
    handleShopFocus (val) {
      if (!this.formInfo.shop_type) {
        return this.$message.error("请先选择应用类型");
      }
      // this.getShopList();
    },
    // 判断是否有销售开卡权限
    isPermission () {
      if (this.saveDisabled) return
      this.saveDisabled = true
      API_Order.getShopExtAuth().then((res) => {
        if (res.open === "OPEN") {
          this.save();
        } else {
          this.$message.error(
            "平台已关闭销售开卡功能，如有其他问题，请联系平台。"
          );
          setTimeout(() => {
            this.handleNoPermission(asyncRouterMap);
          }, 2000);
        }
      });
    },
    // 保存
    save () {
      // 校验表单
      this.$refs.formInfo.validate((validate) => {
        if (validate) {
          if (this.tableValidate()) {
            const params = { ...this.formInfo }
            if (this.isEdit) {
              API_saleCard.editMember(
                {
                  ...params,
                  sn: this.id,
                }
              ).then((res) => {

                this.$message.success("修改成功");
                this.$router.go(-1);
              }).catch((err) => {
                this.saveDisabled = false
              });
            } else {
              API_saleCard.addMember(params).then((res) => {
                this.$message.success("添加成功");
                this.$router.go(-1);
              }).catch((err) => {
                this.saveDisabled = false
              });
            }
          } else {
            this.saveDisabled = false
            this.$message.error("请检查表格项");
          }
        } else {
          this.saveDisabled = false
          this.$message.error("请检查必填项");
        }
      });
    },
    /**表格必填项校验 */
    tableValidate () {
      let status = true;
      if (this.formInfo.account_type == 1) {
        this.phoneTableData.forEach(item => {
          if (item.phone_number == '' || item.money == '') {
            if (item.phone_number == '') {
              item.mobileError = "请填写正确的手机号"
            }
            if (item.money == '') {
              item.moneyError = "请填写扣款金额"
            }
            status = false;
          }
          if (item.mobileError != '' || item.moneyError != '') {
            status = false;
          }
        })
        const arrayList = this.phoneTableData.map(item => {
          return item.phone_number
        })
        if ([...new Set(arrayList)].length !== this.phoneTableData.length) {
          this.$message.error(`列表数据${this.formInfo.account_type == 1 ? '账号' : '手机号'}重复`);
          status = false;
        }
        status ? this.formInfo.trade_recharge_member_dos = this.phoneTableData : ''
      } else {
        this.userTableData.forEach(item => {
          if (item.ccount == '' || item.money == '') {
            if (item.account == '') {
              item.accountError = "请填写正确的帐号"
            }
            if (item.money == '') {
              item.moneyError = "请填写扣款金额"
            }
            status = false;
          }

          if (item.accountError != '' || item.moneyError != '') {
            status = false;
          }
        })
        const arrayList = this.userTableData.map(item => {
          return item.account
        })
        if ([...new Set(arrayList)].length !== this.userTableData.length) {
          this.$message.error(`列表数据${this.formInfo.account_type == 1 ? '账号' : '手机号'}重复`);
          status = false;
        }
        status ? this.formInfo.trade_recharge_member_dos = this.userTableData : ''
      }
      return status
    }
  },
};
</script>

<style scoped lang="scss">
@import "./style/index.scss";

.el-table--enable-row-transition .el-table__body td.el-table__cell {
  position: relative;
}

.errorMessage {
  font-size: 12px;
  color: red;
  position: absolute;
  left: 11px;
  bottom: -3px;
}
</style>
